import useScrollPosition from '@react-hook/window-scroll'
import { SupportedChainId } from 'constants/chains'
import { useEffect, useState } from 'react'
import { switchToNetwork } from 'utils/switchToNetwork'
import { SITE_URL, APP_URL } from '../../config'
import { useActiveWeb3React } from '../../hooks/web3'
import { Menu as MenuIcon, X as CloseIcon } from 'react-feather'
import Menu from '../Menu'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'
import useMobile from '../../hooks/useMobile'

export default function Header() {
  const { account, chainId, library } = useActiveWeb3React()
  const isMobile = useMobile()

  const scrollY = useScrollPosition()
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    if (library && chainId !== SupportedChainId.BLAST_SEPOLIA)
      void switchToNetwork({
        library,
        chainId: SupportedChainId.BLAST_SEPOLIA,
      })
  }, [chainId, library])

  const calcUrl = (nav: string) => `${APP_URL}${nav}`
  if (isMobile) {
    return (
      <>
        <header className="m-header">
          <h1 className="m-header-logo">
            <a href={SITE_URL}>XDX</a>
          </h1>
          <main>
            <Web3Status />
            <MenuIcon onClick={() => setShowMenu(true)} />
          </main>
        </header>
        <div className="m-header-block" />
        <section className={`m-header-menu ${showMenu ? 'active' : ''}`}>
          <div className="m-header-block">
            <CloseIcon onClick={() => setShowMenu(false)} />
          </div>
          <Web3Status />
          <br />
          <Menu mobile />

          <nav className="m-header-nav">
            <a href="/" className="active">
              Swap
            </a>
            <a href={calcUrl('earn')}>Farm</a>
            <a href={calcUrl('launchpad')}>Launchpad</a>
            <a href={calcUrl('social')}>Social</a>
          </nav>
          <NetworkSelector />
        </section>
      </>
    )
  }
  return (
    <header className="web-header">
      <main className="web-header-main">
        <h1 className="web-header-logo">
          <a href={SITE_URL}>XDX</a>
        </h1>
        <hr className="web-header-line" />
        <Menu />
        <hr className="web-header-line" />
        <nav className="web-header-nav">
          <a href="/" className="active">
            Swap
          </a>
          <a href={calcUrl('earn')}>Farm</a>
          <a href={calcUrl('launchpad')}>Launchpad</a>
          <a href={calcUrl('social')}>Social</a>
        </nav>
      </main>
      <div className="web-header-tools">
        <NetworkSelector />
        <hr className="web-header-line" />
        <Web3Status />
      </div>
    </header>
  )
}
