export const SUPPORTED_LOCALES = [
  // order as they appear in the language dropdown
  'en-US',
  // 'fr-FR',
  // 'ja-JP',
  // 'zh-CN',
] as const
export type SupportedLocale = typeof SUPPORTED_LOCALES[number]

export const DEFAULT_LOCALE: SupportedLocale = 'en-US'

export { messages as DEFAULT_MESSAGES } from '../locales/en-US'

export const LOCALE_LABEL: { [locale in SupportedLocale]: string } = {
  'en-US': 'English',
  // 'fr-FR': 'français',
  // 'ja-JP': '日本語',
  // 'zh-CN': '简体中文',
}
